import React, { FunctionComponent as FC } from 'react';
import ViewBill from '../components/view-bill';
import { useQuickViewBill } from '../hooks/useQuickViewBill';
import { AutoPayInfo } from '../__generated__/pge-types';

export const QuickViewBillPage: FC = () => {
  const {
    loading,
    billDetails,
    averageTemperature,
    monthUsage,
    selectedAccount,
    autopay,
    billInfo,
    encryptedAccountNumber,
    encryptedPersonId,
    isPendingDisconnect,
    latestPaymentHistory,
    paymentPlanType,
    tpaDetails,
    pgeEnergyTrackerData,
    isSummaryBillAccount,
  } = useQuickViewBill();

  const paymentHistoryTransform = latestPaymentHistory?.map(payment => ({
    date: payment?.paymentDate,
    amountPaid: payment?.amountPaid,
    type: payment?.paymentType,
  }));

  return (
    <ViewBill
      accountParams={undefined}
      loading={loading}
      billDetails={billDetails}
      selectedAccount={undefined}
      monthUsage={monthUsage}
      averageTemperature={averageTemperature}
      quickSelectedAccount={selectedAccount}
      autopay={autopay as AutoPayInfo}
      billInfo={billInfo}
      isPendingDisconnect={isPendingDisconnect}
      paymentPlanType={paymentPlanType}
      latestPaymentHistory={paymentHistoryTransform}
      encryptedAccountNumber={encryptedAccountNumber}
      encryptedPersonId={encryptedPersonId}
      tpaDetails={tpaDetails || undefined}
      pgeEnergyTracker={pgeEnergyTrackerData}
      isSummaryBillAccount={isSummaryBillAccount}
      isNewAccount={undefined}
    />
  );
};

export default QuickViewBillPage;
