import React, { FunctionComponent, useState, useEffect, useRef } from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';

import { useTranslation } from '../../hooks/useTranslation';
import TextField from '../text-field';
import usePrevious from '../../hooks/usePrevious';

interface AccountSearchProps {
  onSearch: (searchValue: string) => void;
  value?: string;
  styleClass?: string;
  hideSearchIcon?: boolean;
  maxLength?: number;
}

const AccountSearch: FunctionComponent<AccountSearchProps> = props => {
  const { t } = useTranslation();
  const inputEl = useRef<HTMLInputElement>();

  const [searchValue, setSearchValue] = useState<string>(props.value || '');
  const previousProp = usePrevious(props.value);
  useEffect(() => {
    if (
      props.value !== undefined &&
      previousProp !== null &&
      searchValue !== props.value
    ) {
      setSearchValue(props.value);
    }
  }, [props.value]);

  const onUpdate = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
    if (e.target.value === '') {
      props.onSearch('');
    }
  };

  const onSearch = (e: any) => {
    inputEl?.current?.blur();
    e.preventDefault();
    if (props.value !== searchValue) {
      props.onSearch(searchValue);
    }
  };

  return (
    <form onSubmit={onSearch}>
      <TextField
        inputRef={inputEl}
        value={searchValue}
        className={props?.styleClass}
        inputProps={{
          maxLength: props.maxLength ? props.maxLength : '',
        }}
        InputProps={
          props?.hideSearchIcon
            ? null
            : {
                endAdornment: (
                  <InputAdornment position="end" onClick={e => onSearch(e)}>
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }
        }
        onChange={onUpdate}
        label={t('SEARCH_ACCOUNTS')}
        type="search"
        fullWidth
      />
    </form>
  );
};

export default AccountSearch;
