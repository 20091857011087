import {
  ViewBillDetails,
  ViewBillAverageTemperature,
  ViewBillMonthlyUsage,
  QuickViewBillInfo,
  BillInfo,
  AutoPayEnrollStatus,
  PaymentPlanType,
  Maybe,
  PaymentHistory,
  PgeEnergyTrackerData,
} from '../__generated__/pge-types';
import { useEffect, useMemo } from 'react';
import usePgeQuery from './usePgeQuery';
import { navigate } from 'gatsby';
import routes from '../routes';
import { TpaAccountDetails } from '../components/need-more-time-to-pay/tpa/useTPA';
import { QuickViewBillQuery } from '../queries/getQuickViewBillInfo.query';

export type AccountParamsType = {
  accountNumber: string | null | undefined;
};

function getToken(querySearchParam: string): string {
  const x = querySearchParam.split('request=', 2);
  return x.length === 2 ? x[1] : '';
}

interface QuickViewBillHook {
  loading: boolean;
  billDetails: ViewBillDetails | undefined | null;
  averageTemperature: ViewBillAverageTemperature | undefined | null;
  monthUsage: ViewBillMonthlyUsage | undefined | null;
  selectedAccount: QuickViewBillInfo | undefined | null;
  latestPaymentHistory: Maybe<PaymentHistory>[] | undefined | null;
  billInfo: BillInfo | undefined | null;
  autopay: AutoPayEnrollStatus | undefined | null;
  isPendingDisconnect: boolean | undefined;
  paymentPlanType: PaymentPlanType | undefined | null;
  encryptedAccountNumber: string | undefined | null;
  encryptedPersonId: string | undefined | null;
  tpaDetails: TpaAccountDetails | undefined | null;
  pgeEnergyTrackerData: PgeEnergyTrackerData | undefined;
  isSummaryBillAccount: boolean;
}

export const useQuickViewBill = (): QuickViewBillHook => {
  const token = useMemo(() => {
    return typeof window !== 'undefined'
      ? getToken(window.location.search)
      : '';
  }, []);

  const { data, loading, error } = usePgeQuery<{
    getQuickViewBillInfo: QuickViewBillInfo;
  }>(QuickViewBillQuery, {
    variables: {
      token: token,
    },

    errorPolicy: 'all', // stop it going to default error page
  });

  useEffect(() => {
    if (!loading && data) {
      const billDetails = data.getQuickViewBillInfo;
      const isTokenExp = billDetails?.viewBillDetails?.isTokenExpired;
      const AccNumber = billDetails?.accountNumber || '';

      if (!token || error || !AccNumber || isTokenExp || !billDetails) {
        redirectToSigninPage(AccNumber);
      }
    }
  }, [data, loading]);

  const redirectToSigninPage = (accountNumber: string = '') => {
    const redirect_account_param = accountNumber
      ? '?selectedAccount=' + accountNumber
      : '';

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    void navigate(routes.SIGN_IN, {
      state: {
        redirectUrl: routes.VIEW_BILL + redirect_account_param,
      },
    });
  };

  const getViewBillInfo = data?.getQuickViewBillInfo;
  const viewBillDetails: ViewBillDetails | null | undefined =
    getViewBillInfo?.viewBillDetails;
  const viewBillAverageTemperature =
    getViewBillInfo?.viewBillAverageTemperature;
  const viewBillMonthUsage = getViewBillInfo?.viewBillMonthUsage;

  const selectedAccount = {
    accountNumber: getViewBillInfo?.accountNumber,
    addressLine1: getViewBillInfo?.addressLine1,
    customerName: getViewBillInfo?.customerName,
    lastPaymentDetails: getViewBillInfo?.lastPaymentDetails,
    accountType: getViewBillInfo?.accountType,
    isPaperlessBillEnrolled: getViewBillInfo?.isPaperlessBillEnrolled,
    preferredDueDate: getViewBillInfo?.preferredDueDate,
    paymentPlanType: getViewBillInfo?.equalpay?.paymentPlanType,
    autopay: getViewBillInfo?.autoPay,
  };

  return {
    loading: loading,
    billDetails: viewBillDetails,
    averageTemperature: viewBillAverageTemperature,
    monthUsage: viewBillMonthUsage,
    selectedAccount,
    latestPaymentHistory: getViewBillInfo?.paymentHistoryDetails,
    billInfo: getViewBillInfo?.billInfo,
    autopay: getViewBillInfo?.autoPay,
    isPendingDisconnect:
      getViewBillInfo?.pendingDisconnect?.isPendingDisconnect,
    paymentPlanType: getViewBillInfo?.equalpay?.paymentPlanType,
    encryptedAccountNumber: getViewBillInfo?.encryptedAccountNumber,
    encryptedPersonId: getViewBillInfo?.encryptedPersonId,
    tpaDetails: getViewBillInfo?.tpa as TpaAccountDetails,
    pgeEnergyTrackerData: getViewBillInfo?.pgeEnergyTracker as PgeEnergyTrackerData,
    isSummaryBillAccount: getViewBillInfo?.isSummaryBillAccount!,
  };
};
